<template>
	<div class="mhome">
		<div class="mhome-wrap">
			<div class="history-list">
				<div class="item" v-for="(item,index) in historyList" :key="index">
					<div class="item-head">
						<img v-if="[1,3].includes(item.step)" :src="item.portrait" @error="userLoadError" alt="" class="pic">
						<img v-else :src="item.portrait" @error="systemLoadError" alt="" class="pic">
						<div class="head-main">
							<div class="name">{{item.user_name}}</div>
							<div class="time">{{item.create_time | parseTime()}}</div>
						</div>
					</div>
					<div class="item-box" v-html="item.content"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { fetchRefundInfo } from "@/api/mobile/order"
export default {
	data() {
		return {
			refundInfo: {},
			historyList: []
		}
	},
	created() {
		this.getHistoryList()
	},
	methods: {
		userLoadError(e) {
			e.target.src = this.$imageErrorUser;
			e.target.style.objectFit = 'fill';
		},
		systemLoadError(e) {
			e.target.src = this.$imageErrorAvatar;
			e.target.style.objectFit = 'fill';
		},
		getHistoryList() {
			fetchRefundInfo({ apply_refund_id: this.$route.params.id }).then(response => {
				this.refundInfo = response.data || null
				this.historyList = response.data.apply_refunds
			}).catch(() => {
				this.$router.go(-1)
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.history-list {
	.item {
		padding: 0.384rem;
		background-color: #ffff;
		margin-bottom: 0.32rem;
		box-shadow: 0 1px 3px rgba(0,0,0,.1);
		.item-head {
			display: flex;
			align-items: center;
			.pic {
				width: 0.96rem;
				height: 0.96rem;
				border-radius: 0.128rem;
			}
			.head-main {
				flex: 1;
				width: 0;
				margin-left: 0.32rem;
				.name {
					font-size: 0.448rem;
					line-height: 0.64rem;
				}
				.time {
					color: #999999;
					font-size: 0.384rem;
					line-height: 0.544rem;
				}
			}
		}
		.item-box {
			margin-top: 0.256rem;
			font-size: 0.384rem;
			line-height: 0.64rem;
			text-align: justify;
		}
	}
}
</style>